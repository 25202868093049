<template>
  <v-row class="pe-6">
    <v-col cols="12" md="4" sm="6">
      <v-row justify="end" no-gutters>
        <v-col class="mb-3" cols="auto">
          <v-btn :disabled="updatingPhoto || !logo" :loading="updatingPhoto" color="secondary" outlined
                 @click="updatePhoto">
            {{ $vuetify.lang.t('$vuetify.pages.dashboard.gallery.updateBtn') }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <kurcc-image-input :image.sync="logo" :name="$vuetify.lang.t('$vuetify.pages.dashboard.gallery.logo')" dense/>
        </v-col>
        <v-col cols="12">
          <kurcc-image-preview :src="logo" alt="logo"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <v-row justify="end" no-gutters>
        <v-col class="mb-3" cols="auto">
          <v-btn :disabled="updatingPhoto || !cover" :loading="updatingPhoto" color="secondary" outlined
                 @click="updatePhoto">
            {{ $vuetify.lang.t('$vuetify.pages.dashboard.gallery.updateBtn') }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <kurcc-image-input :image.sync="cover" :name="$vuetify.lang.t('$vuetify.pages.dashboard.gallery.cover')" dense
                             rules=""/>
        </v-col>
        <v-col v-if="coverSelected" cols="12">
          <kurcc-image-preview :src="cover" alt="cover photo"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'KurccGalleryPage',
  components: {
    KurccImageInput: () => import('@/modules/app/components/KurccImageInput'),
    KurccImagePreview: () => import('@/modules/app/components/KurccImagePreview')
  },
  data () {
    return {
      cover: null,
      logo: null,
      updatingPhoto: false
    }
  },
  computed: {
    coverSelected () {
      return this.cover
    }
  },
  methods: {
    updatePhoto () {
      this.updatingPhoto = true
      this.$store.dispatch('updateSitePhoto', {
        id: this.$auth.user().site.id,
        cover: this.cover,
        logo: this.logo
      }).finally(() => {
        this.updatingPhoto = false
      })
    }
  },
  created () {
    this.cover = this.$lodash.cloneDeep(this.$auth.user().site.cover)
    this.logo = this.$lodash.cloneDeep(this.$auth.user().site.logo)
  }
}
</script>
